<template>
    <RouterLink :to="link">
       <div class="card invoice-card">
          <div class="invoice-card__item">
             <img :src="img" alt="image"
                                            class="img-fluid rounded avatar-50 mr-3">
            <span>{{ name }}</span>
            </div>  
            <div class="invoice-card__item">
            <p>{{sku}}</p>
            </div> 
            <div class="invoice-card__item">
            <p>AED {{ price }}</p>
            </div> 
            <div class="invoice-card__item">
               <product-status status="enabled"/>
            </div>  
        </div>
    </RouterLink>
      </template>
      <script>
    import { RouterLink } from 'vue-router'
    import productStatus from '../../orders/modals/product-status.vue'
    var CryptoJS = require("crypto-js");
      export default {
        name: "ProductList",
        components: { RouterLink, productStatus },
        props: {
            sku: { type: String, default: "" },
            name: { type: String, default: "" },
            img: { type: String, default: "" },
            status: { type: String, default: "" },
            price: { type: String, default: "" },
            link: { type: String, default: "" }
        },
        methods: {
      orderlink(entityId) {
            return '/order/' + entityId + '/' + CryptoJS.SHA256(this.number + this.link, "OmniAutomotive@777").toString(CryptoJS.enc.Hex) + '/' + this.number + '/overview'
        }
    },
        computed: {
            hasHeaderTitleSlot() {
                return !!this.$slots.headerTitle;
            },
            hasHeaderActionSlot() {
                return !!this.$slots.headerAction;
            },
            hasBodySlot() {
                return !!this.$slots.body;
            },
            hasFooterSlot() {
                return !!this.$slots.footer;
            }
        }
    }
      </script>
      <style scoped lang="scss">
      .invoice-card{
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        padding: 20px;
        &__item{
        display: flex;
        align-items: center;
        gap: 15px;
        justify-content: flex-end;
        color: #03102A;
        p{
            margin: 0;
        }
        &:first-child{
        justify-content: flex-start;
        }
        .icon{
          background: #03102A;
          width: 50px;
          height: 50px;
          display: flex;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          svg{
            width: 30px;
            height: 30px;
            fill: #fff !important;
          }
        }
        }
        &:hover {
            background: #f3f6f9;
        }
      }
    </style>