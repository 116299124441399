<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <titlesectionList :title="title" :link="link" :linktext="linktext" />
                <h6 class="mb-3">Filters</h6>
                <ul class="filter-bar">
                    <li v-for="filter in availableFilters" :key="filter.id" class="nav-item nav-icon dropdown"
                        v-nav-toggle>
                        <a href="#" class="filter-btn btn border add-btn dropdown-toggle shadow-none"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="las la-plus mr-2"></i>
                            {{ filter.name }}
                        </a>
                        <ProductFilters :name="filter.name" :filter-id="filter.id"
                            :is-multi-select="filter.isMultiSelect" />
                    </li>
                </ul>
                <div v-for="active in activeFilters" :key="active.id">
                    <b-button v-if="active.isActive" @click="removeFilter(active.id)" variant="outline-success"
                        class="mb-1">
                        {{ active.name }}: {{ active.filterValue }}<b-badge variant="success" class="ml-2">X</b-badge>
                    </b-button>
                </div>
            </div>
            <div class="col-lg-12">
                <ProductList v-for="product in (products.items)" :key="product.id"
                    :sku='product.sku' :status='product.status' :img='urlPath + product.media_gallery_entries[0].file' :name='product.name' :price="product.price" :link="orderlink(product.sku)"/>
            </div>
        </div>
        <!-- Page end  -->
    </div>
</template>
<script>
import dateFormat from "dateformat";
import ProductFilters from '../../../components/omni/filters/products/product-filter'
import ProductList from '../../../components/omni/orders/modals/product-list.vue'
export default {
    name: 'ListPurchases',
    components: { ProductFilters, ProductList },
    beforeMount() {
        this.$store.dispatch('products/fetchProductList');
    },
    computed: {
        products() {
            return this.$store.state.products.productList
        },
        startDate() {
            let startDate = this.selectedDate[0]
            return dateFormat(startDate, "isoDate")
        },
        endDate() {
            let startDate = this.selectedDate[1]
            return dateFormat(startDate, "isoDate")
        },
        availableFilters() {
            return this.$store.getters['productFilters/getFilters'];
        },
        activeFilters() {
            return this.$store.getters['productFilters/getFilters'];
        },
        urlPath(){
            return this.$store.getters['getUser'].image_base_url
        }
    },
    methods: {
        orderlink(sku) {
            return '/products/' + sku + '/overview'
        },
        removeFilter(filterId) {
            this.$store.commit('productFilters/setFilter', { id: filterId, status: false, filterValue: '' });
            this.$store.dispatch('products/fetchProductList');
        }
    },
    data() {
        return {
            selectedDate: [
                new Date()],
            title: "Products",
            desc1: "A purchase dashboard enables purchasing manager to efficiently track, evaluate,",
            desc2: "and optimize all acquisition processes within a company.",
            link: { name: 'purchase.addpurchase' },
            linktext: "Add Purchase"
        }
    }
}
</script>
<style lang="scss">
.filter-bar {
    display: flex;
    gap: 15px;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    background: #f4f5fa;
    padding: 20px;
    border-radius: 8px;

    .filter-btn {
        background-color: #fff !important;
    }
}
</style>