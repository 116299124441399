<template>
    <div class="iq-sub-dropdown dropdown-menu filter-component" aria-labelledby="dropdownMenuButton2">
        <div class="card shadow-none m-0">
            <div class="card-body p-0" v-if="isMultiSelect">
                <div class="cust-title p-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <h5 class="mb-0">{{ name }}</h5>
                    </div>
                </div>
                <div>
                    <treeselect :multiple="true" :options="options" placeholder="Select your favourite(s)..."
                        v-model="listSelected" />
                    <treeselect-value :value="value" />
                </div>
                <a @click="setFilterMultiselect" class="right-ic btn btn-primary btn-block position-relative p-2" href="#"
                    role="button">
                    Apply
                </a>
            </div>
            <div class="card-body p-0" v-else>
                <div class="cust-title p-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <h5 class="mb-0">{{ name }}</h5>
                    </div>
                </div>
                <div class="form-group"><label>Rule</label><select class="form-control mb-3">
                        <option value="1">Like</option>
                        <option value="2">Equal to</option>
                        <option value="3">Three</option>
                    </select></div>
                <div class="input-group mb-4">
                    <div class="input-group-prepend"><span id="basic-addon1" class="input-group-text">@</span></div>
                    <input v-model="filterText" type="text" placeholder="Username" aria-label="Username"
                        aria-describedby="basic-addon1" class="form-control">
                </div>
                <a @click="setFilter" class="right-ic btn btn-primary btn-block position-relative p-2" href="#"
                    role="button">
                    Apply
                </a>
            </div>
        </div>
    </div>
</template>
<script>
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
    components: { Treeselect },
    props: {
        name: { type: String },
        filterId: { type: String },
        isMultiSelect: { type: Boolean }
    },
    data() {
        return {
            filterText: '',
            options: [
            {
                id: 'complete',
                label: 'complete'
            },
            {
                id: 'pending',
                label: 'pending'
            },
            {
                id: 'cancelled',
                label: 'cancelled'
            }
        ],
            listSelected: []
        }
    },
    methods: {
        setFilter() {
            this.$store.commit('productFilters/setFilter', { id: this.filterId, status: true, filterValue: this.filterText });
            this.$store.dispatch('products/fetchProductList');
        },
        setFilterMultiselect() {
            this.$store.commit('productFilters/setFilter', { id: this.filterId, status: true, filterValue: this.listSelected });
            this.$store.dispatch('products/fetchProductList');
        }
    }
}
</script>
<style lang="scss">
.filter-component {
    min-width: 300px;
    padding: 10px;
}
</style>